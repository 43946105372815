import React from 'react'
import PropTypes from 'prop-types'
import { isOdd } from '../../lib/util'
import distance from '@turf/distance'
import { round } from '../../lib/util'
import { getDate, stringParser, consolidate } from '../../lib/dates'
import logErr, { logMsg } from '../../lib/err'

function PollingHours({ hours }) {
  const uncleaned = stringParser(hours)
  const entries = consolidate(stringParser(hours))
  return (
    <div>
      {process.env.NODE_ENV === 'development' &&
        uncleaned.map((hour, index) => {
          // try to separate the date from the time
          const count = (hour.match(/:/g) || []).length
          let parts
          // date is before the first :
          if (isOdd(count)) {
            const idx = hour.indexOf(':')
            parts = [hour.substr(0, idx), hour.substr(idx + 1)]
          } else parts = [hour]
          return (
            <div key={index} className="d-flex jcsb temp">
              {parts.map((part, idx) => {
                if (parts.length > 1 && idx > 0) return <span key={idx}>{part}</span>
                else return <span key={idx}>{part}</span>
              })}
            </div>
          )
        })}
      {entries.map((e, index) => {
        const { date, time } = getDate(e)
        return (
          <div key={index} className="d-flex jcsb opening-times">
            <b>{date}</b>
            <p>{time}</p>
          </div>
        )
      })}
    </div>
  )
}

PollingHours.propTypes = {
  hours: PropTypes.string.isRequired,
}

function VotingLocation({ site, myLocation, parseOpt }) {
  if (!site || !site.address) {
    // TODO: should be caught with a message: "Not yet available for you location" ????
    console.log('VotingLocation fail', site)
    logMsg('VotingLocation fail')
    return null
  }
  const { address, dist, endDate, latitude, longitude, name, notes, pollingHours, sources, startDate, voterServices } = site
  const locName = name || address?.locationName || ''

  const fallbackDist =
    myLocation && latitude && longitude && round(distance([myLocation.lat, myLocation.lon], [latitude, longitude], { units: 'miles' }), 1) + ' mi'
  const { city, line1, state, zip } = address
  return (
    <div className="voting-location">
      <div className="place">
        <b>{locName}</b>
        <b>{dist || fallbackDist || ''}</b>
      </div>
      <div className="address">
        <p>{line1}</p>
        <p>
          {city}, {state}, {zip}
        </p>
      </div>
      <PollingHours hours={pollingHours} />
    </div>
  )
}

VotingLocation.propTypes = {
  site: PropTypes.shape({
    address: PropTypes.object,
    endDate: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    name: PropTypes.string,
    notes: PropTypes.string,
    pollingHours: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, official: PropTypes.bool })),
    startDate: PropTypes.string,
    voterServices: PropTypes.string,
    dist: PropTypes.string,
  }),
  myLocation: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
  }),
  parseOpt: PropTypes.shape({
    lb: PropTypes.string, // line break character
    sep: PropTypes.string, // data/time separator
  }),
}

export default VotingLocation
