import format from 'date-fns/format'
import parse from 'date-fns/parse'

// Extracts number from string
export const getNumber = (str) => {
  if (!str) {
    return null
  }
  const m = str.match(/\d+/)
  if (m && m[0]) {
    return parseInt(m[0])
  } else {
    return null
  }
}

export const getMonth = (str) => {
  if (str.includes('Oct')) return 'Oct'
  if (str.includes('Nov')) return 'Nov'
  return null
}

// Returns "Oct 3" from '10/03/2020'
export const getShortDate = (dateStr) => {
  try {
    const d = parse(dateStr, 'MM/dd/yyyy', new Date())
    return format(d, 'MMM dd')
  } catch (e) {
    try {
      dateStr = dateStr.replace(/-0+/g, '-')
      // lternate time format in early voting days in Indiana
      const d = parse(dateStr.replace(/-0+/g, '-'), 'yyyy-MM-dd', new Date())
      return format(d, 'MMM d')
    } catch {
      return dateStr
    }
  }
}

// splits opening hour strings by first ':'
export const getDate = (str) => {
  if (!str) {
    return { date: null, time: null }
  }
  const seperator = str.indexOf(':')
  const date = str.substring(0, seperator)
  let time = str.substring(seperator + 1)
  time = time.replace(' am', 'am').replace(' pm', 'pm')
  return { date, time }
}

// meant to parse timespans like "Oct 3 - Nov 3", etc, return `Oct 3 - 30` or `Oct 3 - Nov 3`
export const shortedDateSpan = (startDate, endDate) => {
  if (getMonth(startDate) === getMonth(endDate)) {
    return `${startDate} - ${getNumber(endDate)}`
  }
  else return `${startDate} - ${endDate}`
}

// tries to remove duplicate entries where dates are consecutive and openging times are the same
export const consolidate = (rows) => {
  let res = []
  let queue = [rows[0]]
  rows.forEach((r, i) => {
    const { date, time } = getDate(r)
    const next = rows[i + 1]
    const nextDayTime = getDate(next)
    if (getNumber(date) === getNumber(nextDayTime.date) - 1 && time === nextDayTime.time) {
      queue = [...queue, r]
    } else {
      // different time or non-consecutive
      const startDate = getDate(queue[0])
      const endDate = getDate(r)
      let timeSpan = startDate.date === endDate.date ? startDate.date : `${shortedDateSpan(startDate.date, endDate.date)}`
      if (queue.length === 2) {
        timeSpan = timeSpan.replace('-', '&')
      }
      res = [...res, `${timeSpan}: ${startDate.time}`]
      queue = next ? [next] : []
    }
  })
  return res
}

// removes weekday strings like "Mon," and "Monday"
export const stripWeekDays = (str) => {
  return str
    .replaceAll('Mon,', '')
    .replaceAll('Tue,', '')
    .replaceAll('Wed,', '')
    .replaceAll('Thu,', '')
    .replaceAll('Fri,', '')
    .replaceAll('Sat,', '')
    .replaceAll('Sun,', '')
    .replaceAll('Monday', '')
    .replaceAll('Tuesday', '')
    .replaceAll('Wednesday', '')
    .replaceAll('Thursday', '')
    .replaceAll('Friday', '')
    .replaceAll('Saturday', '')
    .replaceAll('Sunday', '')
    .trim()
}

export const removeYearAndFormat = (str) => {
  // const regex = RegExp(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/g)
  const res = str.replaceAll('/2020', '')
  return res
}

// main hunction to parse opening hour strings
export const stringParser = (hours) => {
  let str = new String(hours)
  // parse rows as an exclusive list because some rows have both
  if (str.indexOf('lt;BR&gt;') > -1) {
    str = str.replaceAll('&lt;BR&gt;', ';').replaceAll('lt;BR&gt;', '|')
  } else if (str.indexOf('\n') > -1) {
    str = str.replaceAll('\n', '|')
  } else if (str.indexOf('Open') > -1) {
    // IN case: "Open Tuesday 10/20/2020 until Friday 10/23/2020 from 12:00PM to 7:00PM"
    str = str.replaceAll('Open', '').replaceAll('until', '-').replaceAll('from', ':').replaceAll('to', '-')
    str = removeYearAndFormat(str)
  } else if (str.indexOf(';') > -1) {
    // SF case: "Oct 5 - Nov 2;Mon - Fri: 8am - 5pm;" use 2nd ;
    if (str.indexOf(';Mon - Fri') > -1) {
      str = str.replaceAll('pm;', 'pm|')
    } else {
      str = str.replaceAll(';', '|')
    }
  }
  return stripWeekDays(str).split('|')
}
