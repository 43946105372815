export const round = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

export const isEven = (num) => num % 2 === 0
export const isOdd = (num) => !isEven(num)

export const trimObj = obj => {
  const res = {}
  Object.keys(obj).forEach(o => {
    if (typeof obj[o] === 'string') {
      res[o] = obj[o].trim()
    } else {
      res[o] = obj[o]
    }
  })
  return res
}